import Script from 'next/script'
/**
 * Perform redirect to /unsupported-browser url if
 * browser is IE.
 * This is a separate script and doesn't use external
 * libraries so that it is much faster to perform the
 * redirect (preventing flashing of the prerendered html)
 */

const redirectIE = (path) => (
  <Script
    id='ie-redirect'
    dangerouslySetInnerHTML={{
      __html: `
          var isIE = /*@cc_on!@*/false || !!document.documentMode;
          if (isIE && window.location.pathname !== "${path}") {
            window.location = "${path}";
          }
        `
    }}
    type='text/javascript'
  />
)

export default redirectIE
