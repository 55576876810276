import NextHead from 'next/head'
import PropTypes from 'prop-types'

import redirectIE from '@tofu/apps/shop/components/common/redirectIE'
import config from '../constants/env'

const Head = ({ children, description, image, noIndex, path, title }) => (
  <NextHead>
    <meta key='charset' charSet='utf8' />
    <meta key='viewport' content='width=device-width' name='viewport' />
    <title>{title}</title>
    <meta key='description' content={description} name='description' />
    <meta content='website' property='og:type' />

    <meta
      content='allplants, vegan, plant-based, meals, frozen, delivered'
      name='keywords'
    />

    <link href={`${config.shop_base_url}${path}`} rel='canonical' />

    {/* Optional: Hide pages from being indexed */}
    {noIndex && <meta content='noindex' name='robots' />}

    {/* <!-- Schema.org markup for Google+ --> */}
    <meta content={title} itemProp='name' />
    <meta content={description} itemProp='description' />
    {image.url && <meta content={image.url} itemProp='image' />}
    {/* <!-- Twitter Card data --> */}
    <meta content='summary' name='twitter:card' />
    <meta content='@allplants' name='twitter:site' />
    <meta content={title} name='twitter:title' />
    <meta content={description} name='twitter:description' />
    {image.Twitter && image.Twitter.url && (
      <meta content={image.Twitter.url} name='twitter:image' />
    )}
    <meta content='allplants' name='twitter:image:alt' />
    {/* <!-- Open Graph data --> */}
    <meta content={title} property='og:title' />
    <meta content='website' property='og:type' />
    <meta content={`${config.shop_base_url}${path}`} property='og:url' />
    {image.Facebook && image.Facebook.url && (
      <meta content={image.Facebook.url} property='og:image' />
    )}
    <meta content={description} property='og:description' />

    <meta content='ie=edge' httpEquiv='x-ua-compatible' />
    {redirectIE('/unsupported-browser')}
    {children}
  </NextHead>
)

Head.defaultProps = {
  children: null,
  image: {},
  noIndex: false,
  path: ''
}

Head.propTypes = {
  children: PropTypes.node,
  description: PropTypes.string.isRequired,
  image: PropTypes.shape({
    Facebook: PropTypes.object,
    Twitter: PropTypes.object,
    url: PropTypes.string
  }),
  noIndex: PropTypes.bool,
  path: PropTypes.string,
  title: PropTypes.string.isRequired
}

export default Head
