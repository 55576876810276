import Script from 'next/script'
import { environment } from '@tofu/shared/constants/environments'

export const OneTrust = () => (
  <Script
    src='https://cdn-ukwest.onetrust.com/scripttemplates/otSDKStub.js'
    type='text/javascript'
    data-domain-script={environment.one_trust_id}
  />
)
