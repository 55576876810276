import Script from 'next/script'

import { environment } from '@tofu/shared/constants/environments'
import { OneTrust } from '@tofu/shared/scripts/one-trust'
import { isProd } from '@tofu/apps/shop/utils/helpers'

const businessSchema = {
  '@context': 'https://schema.org',
  '@type': 'LocalBusiness',
  address: {
    '@type': 'PostalAddress',
    streetAddress: 'Unit 17A',
    addressLocality: 'Uplands Business Park',
    addressRegion: 'London',
    postalCode: 'E17 5QJ',
    addressCountry: 'UK'
  },
  image: `${environment.shop_base_url}/img/allplants-business.jpg`,
  name: 'allplants',
  priceRange: '£4.99 - £6.75',
  telephone: '020 8068 1700',
  url: `${environment.shop_base_url}`,
  email: 'kitchen@allplants.com',
  sameAs: [
    'https://www.facebook.com/allplants/',
    'https://twitter.com/allplants/',
    'https://www.instagram.com/allplants/',
    'https://www.linkedin.com/company/allplants/'
  ]
}

const TrackingScripts = () => {
  const rudderstackWriteKey = isProd
    ? // https://app.rudderstack.com/sources/
      '219L5T146EOLJX8dJPLwHABhroP'
    : '23xklIwRG7gvGXzkBwBTMqp0nzq'
  const rudderstackDataPlaneUrl =
    'https://allplantsubu.dataplane.rudderstack.com'

  return (
    <>
      {environment?.one_trust_id && (
        <>
          <Script id='consent-default'>
            {`window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('consent', 'default', {
              'ad_storage': 'denied',
              'analytics_storage': 'denied',
              'functionality_storage': 'denied',
              'personalization_storage': 'denied',
              'security_storage': 'denied',
              'ad_user_data': 'denied',
              'ad_personalization': 'denied'
            });`}
          </Script>

          <OneTrust />

          <Script
            dangerouslySetInnerHTML={{
              __html: `
          function OptanonWrapper() {
            if (window.OneTrust.IsAlertBoxClosed()) {
              // Load the Rudderstack SDK
              rudderanalytics.load('${rudderstackWriteKey}', '${rudderstackDataPlaneUrl}', {
                cookieConsentManager: {
                  oneTrust: {
                    enabled: true
                  }
                }
              });
            }
          }
          `
            }}
            id='OneTrust Callback'
            type='text/javascript'
          />
        </>
      )}
      <Script
        dangerouslySetInnerHTML={{
          __html: `
            !function(){var e=window.rudderanalytics=window.rudderanalytics||[];e.methods=["load","page","track","identify","alias","group","ready","reset","getAnonymousId","setAnonymousId"],e.factory=function(t){return function(){var r=Array.prototype.slice.call(arguments);return r.unshift(t),e.push(r),e}};for(var t=0;t<e.methods.length;t++){var r=e.methods[t];e[r]=e.factory(r)}e.loadJS=function(e,t){var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.rudderlabs.com/v1/rudder-analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a)},e.loadJS()}();
          `
        }}
        id='rudderstack'
        type='text/javascript'
      />
      <Script
        src={`https://static.cdn.prismic.io/prismic.js?new=true&repo=${environment.prismic_repo}`}
        strategy='lazyOnload'
        type='text/javascript'
      />
      <Script
        src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
        strategy='lazyOnload'
        type='text/javascript'
      />
      <Script
        dangerouslySetInnerHTML={{ __html: JSON.stringify(businessSchema) }}
        id='business-schema'
        type='application/ld+json'
      />
    </>
  )
}

export default TrackingScripts
